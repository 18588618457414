import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import {AuthService} from '../pages/login/auth.service';

declare const $: any;

// Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/equipe',
    title: 'Equipe',
    type: 'sub',
    icontype: 'group',
    collapse: 'equipe',
    children: [
      {path: 'cadastro', title: 'Cadastro de integrantes', ab: 'CI'},
      {path: 'integrantes', title: 'Integrantes Cadastrados', ab: 'IC'},
      /*{path: 'presenca', title: 'Lançar Presença', ab: 'LP'},
      {path: 'presenca-extra', title: 'Lançar Presença Extra', ab: 'LPE'},
      {path: 'presenca-lancadas', title: 'Presenças Lançadas', ab: 'PLS'},*/

      {path: 'presenca-cadastro', title: 'Presenças Função', ab: 'PF'},
      {path: 'presenca-cadastro-extra', title: 'Presenças Função Extra', ab: 'PFE'},
      {path: 'presenca-consulta', title: 'Presenças Função Consulta', ab: 'PFC'},
    ]
  }, {
    path: '/relatorio',
    title: 'Relatório Diário',
    type: 'sub',
    icontype: 'grid_on',
    collapse: 'relatorio',
    children: [
      {path: 'novo', title: 'Fazer Relatório', ab: 'FR'},
      {path: 'todos', title: 'Relatórios Emitidos', ab: 'RE'},
    ]
  }, {
    path: '/as-built',
    title: 'As Built',
    type: 'sub',
    icontype: 'grid_on',
    collapse: 'as-built',
    children: [
      {path: 'memorial', title: 'Memorial Corrigido', ab: 'MC'},
      {path: 'apoio', title: 'Apoio Corrigido', ab: 'AC'},
      {path: 'pendura', title: 'Kit Pendura Corrigido', ab: 'KPC'},
      {path: 'familia-produto', title: 'Problema Família de Produto', ab: 'PFP'},
     /* {path: 'produto-controlado', title: 'Produto Controlado', ab: 'PC'},*/
    ]
  }, {
    path: '/toy',
    title: 'Brinquedo de Parque',
    type: 'sub',
    icontype: 'toys',
    collapse: 'toy',
    children: [
      {path: 'trem', title: 'Trem', ab: 'T'},
      {path: 'carrossel', title: 'Carrossel', ab: 'C'},
      {path: 'roda-gigante', title: 'Roda Gigante', ab: 'RG'},
    ]
  }
];


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  constructor(public authService: AuthService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }

  updatePS(): void  {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement> document.querySelector('.sidebar .sidebar-wrapper');
      let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
    }
  }

  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }

}
