import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Usuario} from './usuario';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private usuarioAutenticado: boolean = false;
  mostrarMenuEmitter = new EventEmitter<boolean>();
  usuario: Usuario;

  constructor(private router: Router, private http: HttpClient) { }

  fazerLogin(usuario: Usuario) {
    if (usuario.email === 'usuario@email.com' && usuario.password === '123456') {
      this.usuarioAutenticado = true;
      this.mostrarMenuEmitter.emit(true);
      this.router.navigate(['/dashboard']);
    } else {
      this.usuarioAutenticado = false;
      this.mostrarMenuEmitter.emit(false);
      return of(false);
    }
    return of(true);
  }

  login(usuario: Usuario): Observable<Usuario> {
    return this.http.post<Usuario>(`${environment.apiUrl}/login`, {email: usuario.email, password: usuario.password})
      .pipe(
        tap(user => this.usuario = user)
      );
  }

  isLoggedIn(): boolean {
    return this.usuario !== undefined;
  }

  handleLogin(path?: string) {
    this.router.navigate(['/pages/login', btoa(path)]);
  }

  usuarioEstaAutenticado() {
    return this.usuarioAutenticado;
  }
}
