import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {AuthGuard} from './guards/auth.guard';

const routes: Routes = [

    {
      path: '',
      redirectTo: 'pages/login',
      pathMatch: 'full'
    }, {
      path: '',
      component: AuthLayoutComponent,
      children: [{
        path: 'pages',
          loadChildren: './pages/pages.module#PagesModule'
      }]
    }, {
      path: '',
      component: AdminLayoutComponent,
      // canActivateChild: [AuthGuard],
      children: [
        {
          path: '',
          loadChildren: './components/dashboard/dashboard.module#DashboardModule',
        }, {
          path: 'equipe',
          loadChildren: './components/equipe/equipe.module#EquipeModule',
        }, {
          path: 'relatorio',
          loadChildren: './components/relatorio/relatorio.module#RelatorioModule',
        }, {
          path: 'as-built',
          loadChildren: './components/as-built/as-built.module#AsBuiltModule',
        }, {
          path: 'toy',
          loadChildren: './components/brinquedo-parque/brinquedo-parque.module#BrinquedoParqueModule'
        }
      ]
    }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
